import { Carousel }       from "@mantine/carousel";
import { Drawer }         from "@mantine/core";
import Autoplay           from "embla-carousel-autoplay";
import React, { useRef }  from "react";
import { useTranslation } from "react-i18next";

import { openAppSingular, storeEvent } from "_analytics/index";
import { useSetInterestialPopup }      from "_common/hooks/global";
import { PrimaryButton }               from "_components/atoms/button";
import Icon                            from "_components/atoms/icon";
import Image                           from "_components/atoms/image";
import { SNG_POPUP_LINK }              from "_config/index";
import useStyles                       from "_styles/molecules/interestialPopup";

const InterestialPopup = ( { popup, onClose } ) => {

	const setInterestialPopup = useSetInterestialPopup ();

	const { classes } = useStyles ( undefined, { name: "styles" } );
	const { t }       = useTranslation ();

	// const autoplay = useRef ( Autoplay ( { delay: 5000 } ) );

	const downloadApp = () => {
		openAppSingular ( SNG_POPUP_LINK, "Pop Up" );

		storeEvent ( "Download", "Download_Popup" );
		setInterestialPopup ( false );

		onClose ();
	};

	const content = (
		<div>
			<div
				className = { classes.closePopupContainer }
				onClick = { () => {
					setInterestialPopup ( false );
					onClose ();
				} }
			>

			</div>

			<div
				className = { classes.slidingPopupContainer }
			>
				<div
					className = {
						classes.closeSVGIcon
					}
					onClick = { () => {
						document.body.style.overflow = "auto";
						setInterestialPopup ( false );

					} }
				>
					{/* <ClosePopup /> */}

					<Icon
						sameInBothTheme
						className = { classes.crossIcon }
						name = "crossWhite"
					/>
				</div>

				<div
					className = { classes.bannerImage }
				>
					<Carousel
						loop
						withIndicators
						// plugins = { [ autoplay.current ] }
						styles = { {
							indicator: {
								width        : 10,
								height       : 10,
								borderRadius : "50%"
							},
							control: {
								opacity: 0
							}
						} }
						transitionDuration = { 350000 }
					>
						<Carousel.Slide>
							<Image
								alt = ""
								className = { classes.carouselImage }
								lazy = { false }
								src = "/images/interestialBannerImg1.webp"
								width = "100%"
							/>

						</Carousel.Slide>

						<Carousel.Slide>
							<Image
								alt = ""
								className = { classes.carouselImage }
								lazy = { false }
								src = "/images/interestialBannerImg2.webp"
								width = "100%"
							/>

						</Carousel.Slide>

						<Carousel.Slide>
							<Image
								alt = ""
								className = { classes.carouselImage }
								lazy = { false }
								src = "/images/interestialBannerImg3.webp"
								width = "100%"
							/>

						</Carousel.Slide>
					</Carousel>
				</div>

				<div>
					<div className = { classes.carouselPointsContainer }>
						<p className = { classes.carouselPoints }>
							<span className = { classes.emoji }>🔥</span>

							{" "}
							Watch & Win Coins and Diamonds
						</p>

						<span className = { classes.carouselPoints }>
							<span className = { classes.emoji }>🔥</span>

							{" "}
							Get Featured on Leaderboards
						</span>

						<span className = { classes.carouselPoints }>
							<span className = { classes.emoji }>🔥</span>

							{" "}
							Redeem Coupons, Spinwheel, UC Cash,
							Paytm & Much More

						</span>
					</div>

					<div
						className = { classes.btnContainer }
					>
						<PrimaryButton
							fullWidth
							className = { classes.downloadBtn }
							onClick = { downloadApp }
							title = { t ( "popup_download" ) }
						>
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Drawer
			classNames = { { drawer: classes.drawer, body: classes.body, content: classes.content } }
			onClose = { onClose }
			opened = { popup }
			overlayProps = { { backgroundOpacity: 0.5, blur: 4 } }
			position = { "bottom" }
			size = "fit-content"
			withCloseButton = { false }
		>
			{content}
		</Drawer>
	);
};

export default InterestialPopup;
